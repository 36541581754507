import StyledLogo from './StyledLogo';

import { useTheme } from '../ThemeContext';
import { HomeData } from '../../constants/homeConst';

import { redirect, getYear } from '../../library';
import { Link, useLocation, useNavigate } from 'react-router-dom';
const Footer = () => {

    const { lang } = useTheme();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handdleScroll = (path) => {
        if (pathname === path) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
            navigate('/');
        }
    }

    return (
        <footer id="footer">
            <StyledLogo specialClass='footer-logo'></StyledLogo>
            <div className="links">
                <nav className='navigator'>
                    <Link to='/' onClick={() => { handdleScroll(`/`) }}><li><h3 className='footer-link'>{HomeData.links.home[lang]}</h3></li></Link>
                    <Link to='/contact'> <li><h3 className='footer-link'>{HomeData.links.contact[lang]}</h3></li></Link>
                </nav>
                {/* <nav className="contact">
                <li onClick={()=>redirect('mailto:francolobos3@gmail.com')}><h3 className='footer-link'>{HomeData.links.email[lang]}</h3></li>
                <li onClick={()=>redirect('https://www.linkedin.com/in/franco-lobos-7142269a/')}><h3 className='footer-link'>LinkedIn</h3></li>
            </nav> */}
            </div>
            <p className="copyritgth">
                &copy; Copyright {getYear()} Kluger Byte
            </p>
        </footer>
    )
}

export default Footer;