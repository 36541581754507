import { useEffect, useState } from 'react';

import StyledLogo from './StyledLogo';

import { useTheme } from '../ThemeContext';
import Switch from './Switch';
import LangSelect from './LangSelect';

import { Link } from 'react-router-dom';


const Header = () => {

    const { theme } = useTheme();
    const [icon, setIcon] = useState('light_mode');

    const toggleIcon = () => {
        setIcon(theme === "light" ? "light_mode" : "dark_mode")
    }

    useEffect(() => {
        toggleIcon();
    }, [theme]);

    useEffect(() => {
        const iconNode = document.getElementById('icon-theme');
        if (!iconNode) return;
        iconNode.innerHTML = icon;
    }, [icon]);



    // useEffect(()=>{
    //     const languages = document.querySelectorAll('.drop-down-item');

    //     if(!languages[0]) return;

    //     window.addEventListener('click', e=>{
    //         if(languages.include(e.target)){
    //             console.log('in');
    //         }
    //     })
    // },[]);

    return (
        <header id="header">
            {/* <StyledLogo specialClass='header-logo'></StyledLogo> */}


            <div className="config">
                <LangSelect></LangSelect>
            </div><div className="config">
                <Switch icon={icon} ></Switch>
            </div>
        </header>
    )
}

export default Header;